<template>
    <div class ="section" id="home">
        <!-- <ul class="bg-animation-box">
            <li class="circle-box"></li>
            <li class="corners-box-20"></li>
            <li class="circle-box"></li>
            <li class="corners-box-20"></li>
            <li></li>
            <li class="corners-box-35"></li>
            <li class="circle-box"></li>
            <li></li>   
        </ul> -->

        <HeroItem/>

    </div>
</template>

<script>
    import HeroItem from '../components/HeroItem.vue'

    export default {
        name: 'HomeSection',
        components: {
            HeroItem,
        }
    }
</script>

<style scoped>
    #home {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #home::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-image: url('../assets/background.png');
        background-size: cover;
        filter: brightness(.2);
        z-index: 0;
        display: block;
    
    }

    /* .bg-animation {
        background: linear-gradient(to left, #a242a8, #2f60ba);
        width: 100%;
        height: 100vh;
    }

    .bg-animation-box {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -1;
    }
    .bg-animation-box li {
        position: fixed;
        display: block;
        list-style: none;
        width: 25px;
        height: 25px;
        background: rgba(255, 255, 255, 0.2);
        animation: bg-animate-01 20s linear infinite;
        bottom: -150px;
    }

    .bg-animation-box li.circle-box {
        border-radius: 50%;
    }

    .bg-animation-box li.corners-box-20 {
        border-radius: 20%;
    }

    .bg-animation-box li.corners-box-35 {
        border-radius: 35%;
    }

    .bg-animation-box li:nth-child(1) {
        left: 4%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
    }
    .bg-animation-box li:nth-child(2) {
        left: 12%;
        width: 30px;
        height: 30px;
        animation-delay: 1.5s;
        animation-duration: 10s;
    }
    .bg-animation-box li:nth-child(3) {
        left: 18%;
        width: 100px;
        height: 100px;
        animation-delay: 5.5s;
    }
    .bg-animation-box li:nth-child(4) {
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 15s;
    }
    .bg-animation-box li:nth-child(5) {
        left: 43%;
        width: 40px;
        height: 40px;
        animation-delay: 0s;
    }
    .bg-animation-box li:nth-child(6) {
        left: 60%;
        width: 110px;
        height: 110px;
        animation-delay: 3.5s;
    }
    .bg-animation-box li:nth-child(7) {
        left: 70%;
        width: 110px;
        height: 110px;
        animation-delay: 6.5s;
    }

    .bg-animation-box li:nth-child(8) {
        left: 95%;
        width: 110px;
        height: 110px;
        animation-delay: 1.5s;
    }

    @keyframes bg-animate-01 {
        0% {
            transform: translateY(0) rotate(0deg);
            opacity: 1;
        }
        100% {
            transform: translateY(-800px) rotate(360deg);
            opacity: 0;
        }
    } */


</style>