<template>
    <main class="hero">
        <div class="hero-text">
            <h1 style="opacity: 1; transform: translate(0px, 0px);">Hi I'm</h1>
            <h2 style="opacity: 1; transform: translate(0px, 0px);">Ethan.</h2>

        </div>

        <aside class="hero-image">
            <img src="../assets/logo.png" alt="Photo of Ethan" style="opacity: 1; transform: translate(0px,0px); box-shadow: rgba(0, 0, 0, 0.55) 0px 0px 15px 0px">
        </aside>
    </main>
</template>

<script>
    export default {
        name: 'HeroItem',
    }
</script>

<style scoped>
    .container {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

    }

    main {
        display: flex;
        flex-flow: row wrap-reverse;
        justify-content: space-between;

    }

    .hero {
        width: 75vw;
    }

    h1 {
        margin: 0;
        font-weight: 300;
        font-size: clamp(32px, 4vw, 52px)
    }

    h2 {
        margin: 0;
        font-size: clamp(62px, 8vw, 140px)
    }
    
    img {
        border: 10px solid #fff;
        border-radius: 50%;
        width: clamp(200px, 20vw, 20vw);

    }

</style>