<template>
  <Home id="home" class="section"/>
</template>

<script>

import Home from './section/HomeSection.vue'

export default {
  name: 'App',
  components: {
    Home,
  }
}
</script>

<style>
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
  color: #fff;

}

body {
  background-color: #000;
  margin: 0;
  padding: 0;
}

</style>
